import { ModalWrapper, useAdditionalServiceMutation } from 'features/additional-order'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useViewerDispatch, useViewerMainSelector, viewerSlice } from 'viewer/container'

import { INewCutsProps } from './NewCuts.types'

export const NewCuts = ({ caseId, viewerId }: INewCutsProps) => {
  const [valueComment, setValueComment] = useState('')

  const refModal = useRef<any>()
  const { t } = useTranslation()
  const { isNewCuts } = useViewerMainSelector(viewerId)
  const viewerDispatch = useViewerDispatch(viewerId)

  const { error, isError, isLoading, isSuccess } = useAdditionalServiceMutation(caseId)

  const onClose = () => {
    viewerDispatch(viewerSlice.actions.setNewCutsModal(false))
    viewerDispatch(viewerSlice.actions.setModalOpen(false))
  }

  useEffect(() => {
    isNewCuts ? refModal.current.open() : refModal.current.close()
  }, [isNewCuts])

  useEffect(() => {
    if (isSuccess) {
      onClose()
    }
  }, [isSuccess])

  return (
    <ModalWrapper
      title={t('Перерезка')}
      refModal={refModal}
      error={error}
      handleSubmit={() => {}}
      isDisabled={isLoading}
      handleComment={(e) => setValueComment(e.target.value)}
      valueComment={valueComment}
      isError={isError}
      onClose={onClose}
    >
      {t('Перерезка')}
    </ModalWrapper>
  )
}
