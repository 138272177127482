import { Layout } from 'antd'
import { useTypedSelector } from 'app/redux/lib/selector'
import { SCROLLBAR_WIDTH } from 'app/styled/GlobalStyles'
import { checkSameGroup, NewCuts, NewSerialCuts, NewStain, Rescan } from 'features/additional-order'
import { useAnnotationsQuery } from 'features/annotations/api'
import { useCaseReferencesBlocksQuery, useCaseReferencesQuery } from 'features/cases/api/query'
import { MultiplayerProvider } from 'features/multiplayer/lib'
import { ACTION_SHOW_LABELS, ACTION_SHOW_PREVIEW } from 'features/thumbnails/common/utils'
import { ContextThumbnail } from 'features/thumbnails/ui/ContextThumbnail'
import { useWorkspacesQuery, workspacesSlice } from 'features/workspace'
import { useCurrentWorkspaceId } from 'features/workspace/lib'
import { selectAtlasViewerUrlSlideId, viewerPageSlice } from 'pages/viewer'
import { MapParamsProvider } from 'pages/viewer/lib/common/MapsProvider'
import { useViewerIdSlideState, useViewerPageProvided } from 'pages/viewer/lib/common/ViewerPageProvider'
import { CoregistrationProvider } from 'pages/viewer/lib/coregistration/Provider'
import { selectTasksViewerUrlTaskId, SlideInfo } from 'pages/viewer/model/viewerPageSlice'
import { Resizable } from 're-resizable'
import React, { MouseEventHandler, useEffect, useReducer, useRef } from 'react'
import { useQueryClient } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { QUERY_TYPE } from 'shared/api'
import { EventBusProvider } from 'shared/lib/EventBus'
import { isGuestHandler } from 'shared/lib/local-storage'
import { ViewerLayoutContext } from 'shared/lib/viewer'
import { LeftPanelContext } from 'shared/lib/viewer/LeftPanelContext'
import styled from 'styled-components/macro'
import ICase from 'types/ICase'
import ISlide from 'types/ISlide'
import { useViewerDispatch, useViewerMainSelector, viewerSlice } from 'viewer/container'
import { useOnClickOutside } from 'viewer/map/layers/annotations/lib/hooks/useOnClickOutside'
import { ScreenShotModal } from 'viewer/tools/ui/screenshot/ScreenShotModal'

import { BottomToolbarContainer } from './BottomToolbarContainer'
import { LEFT_PANEL_PADDING } from './LeftPanelWrapper'
import ViewerGridContainer from './ViewerGridContainer'

const StyledLayout = styled(Layout)`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
`

const Top = styled.div`
  height: 48px;
  background-color: var(--color-bg-1);
  border-bottom: 1px solid var(--color-border-1);
`

const Bottom = styled.div`
  height: 32px;
  padding: 4px;
  background-color: var(--color-bg-2);
  border-top: 1px solid var(--color-border-1);
  order: 1;
`

const Left = styled(Resizable)<{ display?: boolean }>`
  order: 0;
  flex: 0 1 auto;
  background-color: var(--color-bg-2);
  border-right: 1px solid var(--color-border-1);
  display: ${({ display }) => (display ? 'flex' : 'none')};
  flex-direction: column;
  justify-content: space-between;
  z-index: 11;

  .resizeHandle:hover {
    background: var(--color-purple);
    box-shadow: -1px 0 0 rgb(255 255 255 / 40%);
  }
`

const Main = styled.div`
  order: 0;
  flex: 1;
  display: grid;
`

const GridWrapper = styled.div`
  height: 100%;
  background-color: var(--color-bg-1);
`
const LEFT_PANEL_BORDER_WIDTH = 1

const Right = styled(Resizable)<{ display?: boolean }>`
  order: 2;
  flex: 0 1 auto;
  border-left: ${LEFT_PANEL_BORDER_WIDTH}px solid var(--color-border-1);
  background-color: var(--color-bg-2);
  display: ${({ display }) => (display ? 'block' : 'none')};

  .resizeHandle:hover {
    background: var(--color-purple);
    box-shadow: 1px 0 0 rgb(255 255 255 / 40%);
  }
`

const Wrapper = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  overflow: hidden;
`

const StyledCenter = styled.div`
  order: 1;
  flex-grow: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
`

/** Информация о этикетке для разных слайдов. */
type LabelInfo = {
  /** Идентификатор слайда, соответствующий информации о слайде. */
  [key: number]: SlideInfo
}

type Props = {
  /** Ссылка на левый элемент. */
  leftRef?: HTMLDivElement | null
  /** Верхний слот. */
  topSlot: React.ReactNode
  /** Левый слот (необязательный). */
  leftSlot?: React.ReactNode | undefined
  /** Правый слот (необязательный). */
  rightSlot?: React.ReactNode | undefined
  /** Массив слайдов (необязательный). */
  slides?: ISlide[]
  // type: 'ATLAS' | 'DEFAULT'
}

/** дефолтная ширина левой панели в атласе */
const DEFAULT_ATLAS_WIDTH = '200'
/** минимальная ширина левой панели */
const SMALL_WIDTH = '107'
/** дефолтная ширина левой ппанели в платформе */
const DEFAULT_WIDTH = '187'
/** максимальная ширина левой панели */
const MAX_WIDTH_LEFT_PANEL = '400'
/** разница ширины левой панели с включенно этикеткой и без */
const DIFFERENCE_WIDTH = 2
/**
 * суммарная величина отступов слева и справа
 *
 * нужна для того, чтобы статичная величина отступов не участвовала в динамических расчетах
 ** LEFT_PANEL_PADDING - паддинг левой панели
 ** LEFT_PANEL_BORDER_WIDTH - бордер левой панели
 ** SCROLLBAR_WIDTH - ширина скроллбара
 * */
const PANEL_MARGINS = LEFT_PANEL_PADDING + LEFT_PANEL_PADDING + LEFT_PANEL_BORDER_WIDTH + SCROLLBAR_WIDTH

const ViewerLayout = ({ leftRef, leftSlot, rightSlot, slides, topSlot }: Props) => {
  const queryClient = useQueryClient()
  const menuThumbnailRef = useRef(null)
  const {
    configPanels,
    leftPanelType,
    leftPanelWidth,
    rightPanelType,
    selectedSlides,
    similarThumbnailHover,
    slideThumbnailHover,
    thumbnailMenuPosition,
  } = useTypedSelector((state) => state.viewerPage)
  const [layoutVersion, updateLayoutVersion] = useReducer((prev: number) => prev + 1, 0)

  const currentWorkspaceId = useCurrentWorkspaceId()
  const { activeViewerId } = useViewerPageProvided()
  const { caseId, slideId } = useViewerIdSlideState(activeViewerId)
  const atlasSlideId = useSelector(selectAtlasViewerUrlSlideId)
  const taskSlideId = useSelector(selectTasksViewerUrlTaskId)
  const type = atlasSlideId !== undefined ? 'ATLAS' : taskSlideId ? 'TASK' : 'DEFAULT'
  const viewerDispatch = useViewerDispatch(activeViewerId)
  const viewerGridRef = useRef<HTMLDivElement>(null)
  const { data: annotationsIds } = useAnnotationsQuery(Number(caseId), Number(slideId))
  const { isNewCuts, isNewSerialCuts, isNewStain, isRescan, isScreenshotModalOpen } =
    useViewerMainSelector(activeViewerId)
  const authorities = useTypedSelector((state) => state.user.authorities)
  const isGuest = isGuestHandler(authorities)
  const isDefaultType = type === 'DEFAULT'
  const caseRecord = queryClient.getQueryData<ICase>([QUERY_TYPE.CASE, caseId])
  const isShared = caseRecord?.relation === 'SHARED'
  const isReadOnly = caseRecord?.permissionLevel === 'READ_ONLY' && isShared
  const shouldShowLabelsAndPreview =
    configPanels[ACTION_SHOW_LABELS] && configPanels[ACTION_SHOW_PREVIEW] && !isReadOnly && !isGuest

  const width = isDefaultType ? (shouldShowLabelsAndPreview ? DEFAULT_WIDTH : SMALL_WIDTH) : DEFAULT_ATLAS_WIDTH

  const resizableRef = useRef<Resizable | null>(null)
  const resizableElement = resizableRef.current
  const leftWidth = resizableElement?.state?.width

  // Первый селекченный слайд для "Последовательных срезов"
  const selectedFirstSlideId = selectedSlides.find((slide) => slide.isChecked)?.slideId
  const selectedFirstSlide = slides?.find((slide) => slide.slideId === selectedFirstSlideId)

  const selectedSlidesCheked = selectedSlides.filter((item) => item.isChecked).map((item) => item.slideRererenceId)
  const isSameGroup = checkSameGroup(slides, selectedSlidesCheked)
  const selectedSlidesAmount = selectedSlidesCheked.filter(Boolean).length
  const slideNewSerialCuts = selectedFirstSlide || slides?.find((sl) => sl.slideId === slideThumbnailHover?.slideId)

  // Получаем данные по слайдам загруженные из Лиса
  const { data: caseReferencesSlides } = useCaseReferencesQuery({
    caseId: caseId,
    workspaceId: currentWorkspaceId,
  })
  const { data: caseReferencesBlocks } = useCaseReferencesBlocksQuery({
    caseId: caseId,
    workspaceId: currentWorkspaceId,
  })

  const dispatch = useDispatch()
  const currentWorkspace = useTypedSelector((state) => state.workspaces.currentWorkspace)
  const { data: workspaces } = useWorkspacesQuery()

  if (!currentWorkspace && currentWorkspaceId && workspaces) {
    const newWorkspace =
      workspaces?.find((workspace) => workspace.workspaceId === currentWorkspaceId.toString()) || workspaces[0]
    if (newWorkspace !== undefined) {
      dispatch(workspacesSlice.actions.setCurrentWorkspace(newWorkspace))
    }
  }

  const handleNewSerialCuts = () => viewerDispatch(viewerSlice.actions.setNewSerialCutshotModal(true))
  const handleRescan = () => viewerDispatch(viewerSlice.actions.setRescanModal(true))
  const handleNewCuts = () => viewerDispatch(viewerSlice.actions.setNewCutsModal(true))
  const handleNewStain = () => viewerDispatch(viewerSlice.actions.setNewStainModal(true))

  const onCloseMenu = () => {
    if (!thumbnailMenuPosition.length) return
    dispatch(viewerPageSlice.actions.setThumbnailMenuPosition([]))
    dispatch(viewerPageSlice.actions.setSimilarThumbnailHover(undefined))
    dispatch(viewerPageSlice.actions.setSlideThumbnailHover(undefined))
  }

  useEffect(() => {
    if (!slides) return
    const labelInfo: LabelInfo = {}

    slides.forEach((slide) => {
      const { labelUrl, proxiedLabelUrl, slideId, slideMetadata } = slide
      labelInfo[slideId] = {
        labelUrl: labelUrl,
        proxiedLabelUrl: proxiedLabelUrl,
        rotate: slideMetadata?.commonMetadata?.labelRotation || 0,
        slideId: slideId,
      }
    })
    viewerDispatch(viewerPageSlice.actions.setNewSlides(labelInfo))
  }, [slides?.length])

  // закрываем окно тулзу морфологии при выходе из вьювера
  useEffect(() => () => viewerDispatch(viewerPageSlice.actions.closeTool('SEARCH_MORPHOLOGY')), [])

  useEffect(() => {
    updateLayoutVersion()
  }, [rightPanelType, leftPanelType])

  useOnClickOutside(menuThumbnailRef, onCloseMenu, undefined, thumbnailMenuPosition)
  useEffect(() => {
    if (leftWidth && leftWidth !== SMALL_WIDTH && leftWidth !== DEFAULT_WIDTH)
      dispatch(viewerPageSlice.actions.setLeftPanelWidth(leftWidth))
  }, [leftWidth])

  useEffect(() => {
    if (resizableElement && leftPanelWidth)
      resizableElement.updateSize({ height: '100%', width: String(leftPanelWidth) })
  }, [resizableElement])

  useEffect(() => {
    if (resizableElement) {
      const multiplier = shouldShowLabelsAndPreview ? DIFFERENCE_WIDTH : 1 / DIFFERENCE_WIDTH
      const panelWidth = leftWidth
        ? String(
            Math.min(
              Number(MAX_WIDTH_LEFT_PANEL),
              Math.max(Number(SMALL_WIDTH), (Number(leftWidth) - PANEL_MARGINS) * multiplier + PANEL_MARGINS),
            ),
          )
        : undefined

      if (panelWidth) {
        const { state } = resizableElement
        if (state) {
          resizableElement.updateSize({ height: '100%', width: panelWidth })
        }
      }
    }
  }, [shouldShowLabelsAndPreview])

  useEffect(() => {
    if (caseReferencesBlocks?.length && caseReferencesSlides?.length) {
      dispatch(viewerPageSlice.actions.setCaseReferenceBlocks(caseReferencesBlocks))
      dispatch(viewerPageSlice.actions.setCaseReferenceSlides(caseReferencesSlides))
    }
  }, [caseReferencesBlocks?.length, caseReferencesSlides?.length])

  const handleContextMenu: MouseEventHandler<HTMLDivElement> = (e) => e.preventDefault()

  return (
    <EventBusProvider>
      <LeftPanelContext.Provider value={{ value: leftRef?.clientWidth }}>
        <MultiplayerProvider>
          <Wrapper onContextMenu={handleContextMenu}>
            <Top>{topSlot}</Top>
            <StyledLayout>
              {!!thumbnailMenuPosition.length && (
                <ContextThumbnail
                  onCloseMenu={onCloseMenu}
                  thumbnailMenuPosition={thumbnailMenuPosition}
                  menuRef={menuThumbnailRef}
                  slideThumbnailHover={slideThumbnailHover}
                  similarThumbnailHover={similarThumbnailHover}
                  slides={slides}
                  handleNewSerialCuts={handleNewSerialCuts}
                  handleRescan={handleRescan}
                  handleNewStain={handleNewStain}
                  handleNewCuts={handleNewCuts}
                  isSlidesSameGroup={isSameGroup}
                  selectedSlidesAmount={selectedSlidesAmount}
                />
              )}
              <Left
                // @ts-ignore
                ref={resizableRef}
                display={!!leftPanelType}
                defaultSize={{
                  height: '100%',
                  width,
                }}
                maxWidth={MAX_WIDTH_LEFT_PANEL}
                minWidth={width}
                bounds="parent"
                enable={{
                  bottom: false,
                  bottomLeft: false,
                  bottomRight: false,
                  left: false,
                  right: true,
                  top: false,
                  topLeft: false,
                  topRight: false,
                }}
                onResizeStop={() => {
                  updateLayoutVersion()
                }}
                handleStyles={{ right: { right: -4, width: 3 } }}
                handleClasses={{ right: 'resizeHandle' }}
              >
                {leftSlot}
              </Left>
              <StyledCenter>
                {isScreenshotModalOpen && (
                  <ScreenShotModal
                    key={activeViewerId}
                    mapContainerRef={viewerGridRef}
                    viewerId={activeViewerId}
                    annotationsId={annotationsIds?.ids}
                  />
                )}
                {isNewSerialCuts && (
                  <NewSerialCuts viewerId={activeViewerId} slide={slideNewSerialCuts} caseId={caseId} />
                )}
                {isRescan && <Rescan viewerId={activeViewerId} caseId={caseId} />}
                {isNewCuts && <NewCuts viewerId={activeViewerId} caseId={caseId} />}
                {isNewStain && <NewStain viewerId={activeViewerId} caseId={caseId} />}
                <Main ref={viewerGridRef} id="main-viewer-layout">
                  <GridWrapper>
                    <ViewerLayoutContext.Provider value={{ layoutVersion }}>
                      <MapParamsProvider>
                        <CoregistrationProvider>
                          <ViewerGridContainer viewerGridRef={viewerGridRef} />
                        </CoregistrationProvider>
                      </MapParamsProvider>
                    </ViewerLayoutContext.Provider>
                  </GridWrapper>
                </Main>
                <Bottom>
                  <BottomToolbarContainer />
                </Bottom>{' '}
              </StyledCenter>
              <Right
                className="rightPanel"
                display={!!rightPanelType}
                defaultSize={{
                  height: '100%',
                  width: '264',
                }}
                maxWidth="700"
                minWidth="264"
                enable={{
                  bottom: false,
                  bottomLeft: false,
                  bottomRight: false,
                  left: true,
                  right: false,
                  top: false,
                  topLeft: false,
                  topRight: false,
                }}
                onResizeStop={() => {
                  updateLayoutVersion()
                }}
                handleStyles={{ left: { left: -4, width: 3 } }}
                handleClasses={{ left: 'resizeHandle' }}
              >
                {rightSlot}
              </Right>
            </StyledLayout>
          </Wrapper>
        </MultiplayerProvider>
      </LeftPanelContext.Provider>
    </EventBusProvider>
  )
}

export default ViewerLayout
