import { checkIntegration } from 'entities/lis-integration'
import { getFieldLabelBylisMode } from 'entities/lis-integration/lib/checkIntegration'
import { AdditionalOrderInfo, useAdditionalServiceQuery } from 'features/additional-order'
import { Button, Mark } from 'features/additional-order/ui/AdditionalOrderInfo/AdditionalOrderInfo.styles'
import { useCaseLinkQuery } from 'features/case-info/api/query'
import { useLisMode } from 'features/workspace/model/workspacesSlice'
import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getDiagnosticProcedureLocalize, getIcd10Localized, getLocalizedSiteName } from 'shared/lib/common'
import { ORIGIN_CASE_DATA_KEY } from 'shared/lib/common/storageKeys'
import { formatDateForDisplay } from 'shared/lib/date'
import { Descriptions, DescriptionsItem } from 'shared/ui/description'
import { TitleElement } from 'shared/ui/kit'
import styled from 'styled-components/macro'
import ICase from 'types/ICase'
import { AllCasesToolPanel } from 'viewer/tools/ui/all-cases/AllCasesToolPanel'

const ButtonWrapper = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 18px;
  margin-bottom: 10px;
`
const StyledButton = styled.div`
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  color: var(--color-purple-light);
  margin-right: 4px;
`
/// df
const StyledCountWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 16px;
  height: 16px;
  color: white;
  font-weight: 600;
  font-size: 11px;
  line-height: 16px;
  border-radius: 8px;
  background: var(--color-purple);
  padding: 2px 4px;
`

const StyledDescriptions = styled(Descriptions)`
  .ant-descriptions-item-container {
    display: flex;
    flex-direction: column;
  }
`

type Props = {
  caseRecord: ICase
  isLoading: boolean
  isViewer?: boolean
  /** Флаг управления рендерером длинной информации о кейсе */
  skipLongData?: boolean
  /** Флаг для определения вьювера разметки или атласа */
  isMarkup: boolean
}

const CaseInfo: FC<Props> = ({ caseRecord, isMarkup, isViewer, skipLongData }) => {
  const [addOrderOpened, setAddOrderOpened] = useState<boolean>(false)
  const [allCasesOpened, setAllCasesOpened] = useState<boolean>(false)
  const localOriginPath = localStorage.getItem(ORIGIN_CASE_DATA_KEY)
  const { data: caseLinkDTO } = useCaseLinkQuery({ caseId: caseRecord.caseId, isMarkup })
  const { data: dataAdditionalInfo } = useAdditionalServiceQuery(caseRecord.caseId)
  const { t } = useTranslation()
  const lisMode = useLisMode()
  const { casePathologicalInfo } = caseRecord
  const icd10 = getIcd10Localized(casePathologicalInfo?.incomingIcd10)
  const diagnosticProcedure = getDiagnosticProcedureLocalize(casePathologicalInfo?.diagnosticProcedureType)

  const onOpenAllCasesPanel = () => setAllCasesOpened(!allCasesOpened)
  const onOpenAddOrderPanel = () => setAddOrderOpened(!addOrderOpened)

  useEffect(() => {
    if (localOriginPath) setAllCasesOpened(true)
  }, [])

  return (
    <>
      {/* Имя случая */}
      {isViewer && checkIntegration('caseInfo', 'name', lisMode) && (
        <TitleElement data-testid={`caseName-viewer`} level={3} style={{ marginBottom: 23 }}>
          {caseRecord?.name || t('Не указано')}
        </TitleElement>
      )}
      {isViewer && caseLinkDTO && caseLinkDTO.length > 1 && (
        <ButtonWrapper data-testid={`allcases-viewer`} onClick={onOpenAllCasesPanel}>
          <Button data-testid={`allcases-viewer`} isFocus={allCasesOpened} onClick={onOpenAllCasesPanel}>
            {t('Все случаи пациента')} <Mark>{caseLinkDTO.length}</Mark>
          </Button>
          {allCasesOpened && <AllCasesToolPanel caseRecords={caseLinkDTO} />}
        </ButtonWrapper>
      )}
      {isViewer && dataAdditionalInfo?.length && (
        <AdditionalOrderInfo
          data={dataAdditionalInfo}
          handleClick={onOpenAddOrderPanel}
          isVisiblePanel={addOrderOpened}
        />
      )}
      <StyledDescriptions colon={false}>
        {!skipLongData && (
          <>
            {/* Клинические сведения */}
            {checkIntegration('caseInfo', 'clinicalInfo', lisMode) && casePathologicalInfo?.clinicalInfo && (
              <DescriptionsItem
                style={{ paddingBottom: 8 }}
                label={<span data-testid="clinicalInfo-title">{t('Клинические сведения')}</span>}
                span={3}
              >
                <span data-testid="clinicalInfo-title-content-viewer">{casePathologicalInfo.clinicalInfo}</span>
              </DescriptionsItem>
            )}
            {/* Макроописание */}
            {checkIntegration('caseInfo', 'macroDescription-title', lisMode) && casePathologicalInfo?.macroDescription && (
              <DescriptionsItem
                style={{ paddingBottom: 8 }}
                label={<span data-testid="macroDescription-title">{t('Макроописание')}</span>}
                span={3}
              >
                <span data-testid="macroDescription-title-content-viewer">{casePathologicalInfo.macroDescription}</span>
              </DescriptionsItem>
            )}
          </>
        )}
        {/* Доп. клинические сведения */}
        {checkIntegration('caseInfo', 'additionalInfo', lisMode) && casePathologicalInfo?.additionalInfo && (
          <DescriptionsItem
            style={{ paddingBottom: 8 }}
            label={<span data-testid="additionalInfo-viewer">{t('Доп. клинические сведения')}</span>}
            span={3}
          >
            <span data-testid="additionalInfo-content-viewer">{casePathologicalInfo?.additionalInfo}</span>
          </DescriptionsItem>
        )}
        {/* Номер заказа */}
        {checkIntegration('caseInfo', 'orderNumber', lisMode) && caseRecord.orderNumber && (
          <DescriptionsItem
            style={{ paddingBottom: 8 }}
            label={<span data-testid="orderNumber-viewer">{t('Номер заказа')}</span>}
            span={3}
          >
            <span data-testid="orderNumber-content-viewer">{caseRecord.orderNumber}</span>
          </DescriptionsItem>
        )}
        {/* Отделение, направившее материал / Медицинская организация */}
        {checkIntegration('caseInfo', 'department', lisMode) && casePathologicalInfo?.department && (
          <DescriptionsItem
            style={{ paddingBottom: 8 }}
            label={<span data-testid="department-viewer">{getFieldLabelBylisMode('department', lisMode)}</span>}
            span={3}
          >
            <span data-testid="department-content-viewer">{casePathologicalInfo?.department?.name}</span>
          </DescriptionsItem>
        )}
        {/* Дата заказа / Дата исследования */}
        {checkIntegration('caseInfo', 'caseDate', lisMode) && caseRecord.caseDate && (
          <DescriptionsItem
            style={{ paddingBottom: 8 }}
            label={<span data-testid="caseDate-viewer">{getFieldLabelBylisMode('caseDate', lisMode)}</span>}
            span={3}
          >
            <span data-testid="caseDate-content-viewer">{formatDateForDisplay(caseRecord.caseDate)}</span>
          </DescriptionsItem>
        )}
        {/* Услуга */}
        {checkIntegration('caseInfo', 'services', lisMode) && caseRecord.services && (
          <DescriptionsItem
            style={{ paddingBottom: 8 }}
            label={<span data-testid="services-viewer">{t('Услуга')}</span>}
            span={3}
          >
            <span data-testid="services-content-viewer">
              {caseRecord.services?.map((item, index) => {
                const servicesLength = caseRecord.services?.length || 0
                const isLast = index === servicesLength - 1
                return <div key={item.shortName || item.name}>{`${item.name}${isLast === false ? `, ` : ''}`}</div>
              })}
            </span>
          </DescriptionsItem>
        )}
        {/* МКБ-10 / Диагноз направления */}
        {checkIntegration('caseInfo', 'incomingIcd10', lisMode) && icd10 && (
          <DescriptionsItem
            style={{ paddingBottom: 8 }}
            label={<span data-testid="incomingIcd10-viewer">{getFieldLabelBylisMode('incomingIcd10', lisMode)}</span>}
            span={3}
          >
            <span data-testid="incomingIcd10-content-viewer">
              {icd10.shortName} {icd10.name}
            </span>
          </DescriptionsItem>
        )}
        {/* Дата забора материала / Дата забора */}
        {checkIntegration(
          'caseInfo',
          'materialTakeoutDate',
          lisMode,
          lisMode === 'DZM' ? caseRecord.fieldSet === 'DEFAULT' : true,
        ) &&
          caseRecord.materialTakeoutDate && (
            <DescriptionsItem
              style={{ paddingBottom: 8 }}
              label={
                <span data-testid="materialTakeoutDate-viewer">
                  {getFieldLabelBylisMode('materialTakeoutDate', lisMode)}
                </span>
              }
              span={3}
            >
              <span data-testid="materialTakeoutDate-content-viewer">
                {formatDateForDisplay(caseRecord.materialTakeoutDate)}
              </span>
            </DescriptionsItem>
          )}
        {/* Диагноз основного заболевания / Клинические сведения */}
        {checkIntegration('caseInfo', 'clinicalInfo', lisMode) && isViewer && casePathologicalInfo?.clinicalInfo && (
          <DescriptionsItem
            style={{ paddingBottom: 8 }}
            label={<span data-testid="clinicalInfo-viewer">{getFieldLabelBylisMode('clinicalInfo', lisMode)}</span>}
            span={3}
          >
            <span data-testid="clinicalInfo-content-viewer">{casePathologicalInfo?.clinicalInfo}</span>
          </DescriptionsItem>
        )}
        {/* Макроописание */}
        {caseRecord?.casePathologicalInfo?.macroDescription && isViewer && (
          <DescriptionsItem
            style={{ paddingBottom: 8 }}
            label={<span data-testid="macroDescription-viewer">{t('Макроописание')}</span>}
            span={3}
          >
            <span data-testid="macroDescription-content-viewer">
              {caseRecord?.casePathologicalInfo?.macroDescription}
            </span>
          </DescriptionsItem>
        )}
        {/* Тип материала */}
        {checkIntegration('caseInfo', 'materialType', lisMode) && casePathologicalInfo?.materialType && (
          <DescriptionsItem
            style={{ paddingBottom: 8 }}
            label={<span data-testid="materialType-viewer">{t('Тип материала')}</span>}
            span={3}
          >
            <span data-testid="materialType-content-viewer">{casePathologicalInfo.materialType?.name}</span>
          </DescriptionsItem>
        )}
        {/* Задача прижизненного патологоанатомического исследования */}
        {checkIntegration('caseInfo', 'task', lisMode) && caseRecord.casePathologicalInfo?.task && (
          <DescriptionsItem
            style={{ paddingBottom: 8 }}
            label={
              <span data-testid="task-viewer">{t('Задача прижизненного патологоанатомического исследования')}</span>
            }
            span={3}
          >
            <span data-testid="task-content-viewer">{casePathologicalInfo.task}</span>
          </DescriptionsItem>
        )}
        {/* Способ получения материала / Способ получения */}
        {checkIntegration(
          'caseInfo',
          'diagnosticProcedureType',
          lisMode,
          lisMode === 'DZM' ? caseRecord.fieldSet === 'DEFAULT' : true,
        ) &&
          diagnosticProcedure && (
            <DescriptionsItem
              style={{ paddingBottom: 8 }}
              label={
                <span data-testid="diagnosticProcedureType-viewer">
                  {getFieldLabelBylisMode('diagnosticProcedureType', lisMode)}
                </span>
              }
              span={3}
            >
              <span data-testid="diagnosticProcedureType-content-viewer">{diagnosticProcedure?.name}</span>
              <span />
            </DescriptionsItem>
          )}
        {/* Тип исследованиия */}
        {checkIntegration('caseInfo', 'caseResearchType', lisMode) && casePathologicalInfo?.caseResearchType && (
          <DescriptionsItem
            style={{ paddingBottom: 8 }}
            label={<span data-testid="caseResearchType-viewer">{t('Тип исследования')}</span>}
            span={3}
          >
            <span data-testid="caseResearchType-content-viewer">
              {caseRecord.casePathologicalInfo.caseResearchType?.name}
            </span>
          </DescriptionsItem>
        )}
        {/* Локализация / Биоматериалы */}
        {checkIntegration('caseInfo', 'sites', lisMode) &&
          casePathologicalInfo?.sites &&
          casePathologicalInfo?.sites?.length > 0 && (
            <DescriptionsItem
              style={{ paddingBottom: 8 }}
              label={<span data-testid="sites-viewer">{t('Локализация')}</span>}
              span={3}
            >
              <span data-testid="sites-content-viewer">
                {casePathologicalInfo.sites?.map((item) => (item.site ? getLocalizedSiteName(item.site) : ''))}
              </span>
            </DescriptionsItem>
          )}
      </StyledDescriptions>
    </>
  )
}

export default CaseInfo
