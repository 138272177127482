import { Divider } from 'antd'
import { TextAreaElement } from 'shared/ui/kit'
import { ModalProvider } from 'shared/ui/modal'
import styled from 'styled-components/macro'

export const StyledModal = styled(ModalProvider)`
  .ant-modal-content {
    width: 320px;
  }

  .ant-modal-content .ant-modal-body {
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 16px;
  }

  .ant-modal-content .ant-modal-header {
    padding: 16px 16px 0;
  }
  & .ant-modal-title {
    font-size: 13px;
    line-height: 18px;
  }
`

export const Wrapper = styled.div`
  position: relative;

  & .ant-modal-title {
    font-size: 13px;
  }
`

export const StyledDivider = styled(Divider)`
  border-width: 0 !important;
  //margin-bottom: 12px !important;
  //margin-top: 0 !important;
`

export const Label = styled.label`
  display: flex;
  width: 100%;
  flex-direction: column;
  color: #99989f;
  font-size: 12px;
  white-space: nowrap;
`

export const GroupElems = styled.div`
  display: flex;
  justify-content: space-between;
  column-gap: 16px;
  flex: 1 1 2;
`

export const TextAreaElementStyle = styled(TextAreaElement)`
  width: 100%;

  &.ant-input {
    min-height: 52px;
  }
`

export const ButtonElems = styled.div`
  display: flex;
  justify-content: flex-end;
  column-gap: 8px;
  padding-top: 8px;
`

export const ErrorMessage = styled.div`
  margin-top: 8px;
  padding: 8px;
  background-color: var(--color-red);
  border-radius: 5px;
  font-size: 13px;
  color: var(--color-white);
`
