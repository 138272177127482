import { DictionaryItem } from 'types/IDictionary'

/** Виды дозаказов */
export enum RESEARCH_REQUEST_TYPE {
  /** Пересканирование */
  RESCAN = 'RESCAN',
  /** Перерезка */
  NEW_CUTS = 'NEW_CUTS',
  /** Последовательные срезы */
  NEW_SERIAL_CUTS = 'NEW_SERIAL_CUTS',
}

/** Виды статусов по дозаказам */
export enum STATUS_ADDITIONAL_ORDER {
  CREATION_PENDING = 'CREATION_PENDING',
  CREATED = 'CREATED',
  IN_PROGRESS = 'IN_PROGRESS',
  READY = 'READY',
  CANCELLED = 'CANCELLED',
  FAILED = 'FAILED',
}

export interface ISlideAdditional {
  caseBlockReferenceId?: number
  caseSlideReferenceId?: number
  stain?: DictionaryItem
}

export type NewSerialCutsPayload = {
  newCaseRequested: boolean
  preparationComment?: string
  slides: ISlideAdditional[]
  type: RESEARCH_REQUEST_TYPE
}
