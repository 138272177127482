import { useTranslation } from 'react-i18next'
import { ButtonElement, IconElement } from 'shared/ui/kit'

import {
  ButtonElems,
  ErrorMessage,
  GroupElems,
  Label,
  StyledDivider,
  StyledModal,
  TextAreaElementStyle,
  Wrapper,
} from './ModalWrapper.styles'
import { IModalWrapperProps } from './ModalWrapper.types'

/** Оболочка для модалок по дозаказам  */
export const ModalWrapper = ({
  children,
  error,
  handleComment,
  handleSubmit,
  isDisabled,
  isError,
  onClose,
  refModal,
  title,
  valueComment,
}: IModalWrapperProps) => {
  const { t } = useTranslation()

  return (
    <StyledModal
      title={title}
      ref={refModal}
      centered
      footer={null}
      destroyOnClose
      onCancel={onClose}
      closeIcon={<IconElement name="cross16" size="md" onClick={onClose} />}
    >
      <Wrapper>
        {children}

        <GroupElems>
          <Label>
            {t('Комментарий для лаборанта')}
            <TextAreaElementStyle maxLength={300} value={valueComment} onChange={handleComment} />
          </Label>
        </GroupElems>
        {isError && <ErrorMessage>{error?.message}</ErrorMessage>}
        <StyledDivider />

        <ButtonElems>
          <ButtonElement onClick={onClose}>{t('Отменить')}</ButtonElement>
          <ButtonElement disabled={isDisabled} type="primary" onClick={handleSubmit}>
            {t('Отправить')}
          </ButtonElement>
        </ButtonElems>
      </Wrapper>
    </StyledModal>
  )
}
