/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button } from 'antd'
import { useCaseQuery } from 'features/cases'
import { useCaseAssignToDoctorMutation } from 'features/cases-management/api/query'
import { getQueryParams, refetchPages } from 'features/cases-management/lib/helpers'
import { useCaseManagementContext } from 'features/cases-management/ui/CaseManagementContext'
import { useCaseManagementTabContext } from 'features/cases-management/ui/CaseManagementTabContext'
import { notices } from 'features/notices'
import { useCurrentWorkspaceId } from 'features/workspace/lib'
import { useCaseManagementRouteParam } from 'pages/cases-management/CasesManagementRoutes'
import React, { BaseSyntheticEvent, FC, memo, MouseEvent, useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { useLocation } from 'react-router-dom'
import { useSettingsAndUserRoles } from 'shared/lib/workspaces'
import { TextElement } from 'shared/ui/kit'
import styled from 'styled-components'
import { ECaseStageQuery } from 'types/ICase'
import { IDoctor } from 'types/IUser'
import useDeepCompareEffect from 'use-deep-compare-effect'

/** Используется, чтобы вычислить процентное значение отношения */
const PERCENTAGE_DIVISOR = 100

const styles = [
  {
    background: 'var(--color-blue)',
    color: 'white',
  },
  {
    background: 'var(--color-red)',
    color: 'white',
  },
  {
    background: 'var(--color-green)',
    color: 'white',
  },
  {
    background: 'var(--color-yellow)',
    color: 'black',
  },
  {
    background: 'var(--color-emerald)',
    color: 'white',
  },
  {
    background: 'var(--color-purple)',
    color: 'white',
  },
]

const STYLES_QUANTITY = styles.length

type TProps = {
  /** Выбран текущий доктор */
  isActive: boolean
  /** Колбек нажатия по доктору */
  handleNameClick: () => void
  /** Доктор */
  doctor: IDoctor
  /** Установка флага что случай был распределён */
  setIsCaseAssigned: (arg: boolean) => void
}

export const DoctorNameItem: FC<TProps> = ({ doctor, handleNameClick, isActive, setIsCaseAssigned }) => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const { currentTab, menuTab } = useCaseManagementRouteParam()
  const { tabsQueryParams } = useCaseManagementTabContext()
  const { filterParams, visibleRowsCount } = useCaseManagementContext()
  const { isCaseRouting } = useSettingsAndUserRoles()
  const { search } = useLocation()
  const getNameLetterColor = (name: string) => styles[name.length % STYLES_QUANTITY]
  const { selectedCases, selectedCasesCount, selectedRowKeys, setSelectedCases, setSelectedRowKeys } =
    useCaseManagementTabContext()
  /** Кнопка распределить задизейблина */
  const [isAssignDisabled, setIsAssignDisabled] = useState<boolean>(false)
  const workspaceId = Number(useCurrentWorkspaceId())
  /** Параметры поиска кейса в url*/
  const searchParams = useMemo(() => new URLSearchParams(search), [search])
  /** CaseId из url*/
  const selectedCaseId = searchParams.get('caseId')
  const { data: selectedCaseRecord } = useCaseQuery({ caseId: Number(selectedCaseId), source: 'PLATFORM' })
  const { isLoading: isCaseAssigning, mutate: assignCaseMutate } = useCaseAssignToDoctorMutation(Number(workspaceId), {
    onSuccess: () => {
      refetchPages(
        queryClient,
        menuTab,
        {
          tab: currentTab,
          ...getQueryParams(tabsQueryParams, menuTab, currentTab, isCaseRouting),
        },
        filterParams,
        workspaceId,
        visibleRowsCount,
      )

      setSelectedRowKeys([])
      setSelectedCases([])
      handleNameClick()

      setIsCaseAssigned(true)
      notices.dictributionCases({ doctorName: doctor.fullname, selectedCasesCount: allSelectedCount })
    },
  })

  const { assigned, dailyCapacity, fullname, userId } = doctor
  const style = getNameLetterColor(fullname)
  // TODO изменено для хотфикса 8389
  const allSelectedCount = selectedCasesCount
  useDeepCompareEffect(() => {
    // TODO изменено для хотфикса 8389
    const allSelectedCases = selectedCases
    const isSomeCaseAssigned = allSelectedCases.some((currentCase) => currentCase?.stage === ECaseStageQuery.ASSIGNED)
    const isSomeCaseAssignedToDoctor = allSelectedCases.some(
      (currentCase) => currentCase?.assignedDoctor?.userId === userId,
    )
    if ((isSomeCaseAssigned && isSomeCaseAssignedToDoctor) || !selectedCasesCount) {
      setIsAssignDisabled(true)
    } else {
      setIsAssignDisabled(false)
    }
  }, [selectedCases, userId, selectedCasesCount])

  const handleCaseAssign = useCallback(
    (ev: MouseEvent<HTMLElement>) => {
      ev.stopPropagation()
      if (isCaseAssigning) return
      const checkboxSelectedCaseIds = selectedCases.map((currentCase) => currentCase.caseId)
      // TODO изменено для хотфикса 8389
      const caseIds = checkboxSelectedCaseIds
      assignCaseMutate({ caseIds, userId })
    },
    [selectedCasesCount, userId, isCaseAssigning],
  )

  const onItemClick = (e: BaseSyntheticEvent) => {
    handleNameClick()
    const itemElement = e.target.className == 'doctor-item' ? e.target : e.target.closest('.doctor-item')

    setTimeout(() => {
      itemElement.scrollIntoViewIfNeeded(false)
    })
  }

  return (
    <>
      {doctor && (
        <DoctorItemWrapper
          className="doctor-item"
          onClick={onItemClick}
          style={{ background: isActive ? 'var(--color-border-1)' : 'transparent' }}
          data-testid={`routing-doctors`}
        >
          <NameWrapper key={userId}>
            <StyledDoctorNameLetter style={style}>{fullname[0].toUpperCase()}</StyledDoctorNameLetter>
            <div style={{ display: 'grid', width: '100%' }}>
              <DoctorName>{t(fullname)}</DoctorName>
              <ProgressBar
                todayInCurrentGroup={assigned?.todayInCurrentGroup}
                todayInAllGroups={assigned?.todayInAllGroups}
                last30DaysInAllGroups={assigned?.last30DaysInAllGroups}
                dailyCapacity={dailyCapacity}
              />
            </div>
          </NameWrapper>
          {isActive && (
            <Button
              type="primary"
              style={{ marginTop: '8px' }}
              onClick={handleCaseAssign}
              disabled={isAssignDisabled}
              loading={isCaseAssigning}
              data-testid={`routing-button`}
            >
              {`${t('Распределить')} (${allSelectedCount})`}
            </Button>
          )}
        </DoctorItemWrapper>
      )}
    </>
  )
}

export const DoctorNameCellTable = memo(({ doctor, key }: { doctor: string; key: React.Key }) => {
  const { t } = useTranslation()

  const getNameLetterColor = (doctor: string) => styles[doctor?.length % STYLES_QUANTITY]
  const style = getNameLetterColor(doctor)

  return (
    <>
      {doctor ? (
        <TableNameWrapper key={key}>
          <StyledDoctorNameLetter style={style}>{doctor[0].toUpperCase()}</StyledDoctorNameLetter>{' '}
          <TextElement ellipsis>{doctor ? doctor : t('Не назначен')}</TextElement>
        </TableNameWrapper>
      ) : (
        <p style={{ color: 'var(--color-text-3)' }}>{t('Не назначен')}</p>
      )}
    </>
  )
})

type Props = {
  /** Распределено за сегодня в выбранной группе (случаев) */
  todayInCurrentGroup: number
  /** Распределено за сегодня в любых группах (случаев) */
  todayInAllGroups: number
  /** Распределено за 30 предыдущих дней в любых группах (случаев) */
  last30DaysInAllGroups: number
  /** Дневная емкость врача, заданная заведующим (случаев/сутки) */
  dailyCapacity: number
}

const ProgressBar = ({ dailyCapacity, last30DaysInAllGroups, todayInAllGroups }: Props) => {
  const calculatePercentage = (x1: number, x2: number) =>
    x2 === 0 || x2 === null ? 0 : Math.round((x1 / x2) * PERCENTAGE_DIVISOR)
  /** Утилизация емкости врача во всех группах */
  const greenProgress = calculatePercentage(todayInAllGroups || 0, dailyCapacity || 0) || 0

  const renderProgressCount = () => {
    const parts = [todayInAllGroups, last30DaysInAllGroups].filter((part) => part !== null && part !== undefined)
    return parts.join('/')
  }
  return (
    <>
      <ProgressBarWrapper data-testid={'green-progress-bar-elorish'}>
        <Progress greenProgress={greenProgress} />
      </ProgressBarWrapper>
      <StyledProgressBarWrapper>
        <ProgressText data-testid={'progress-bar-todayInAllGroups-last30DaysInAllGroups-elorish'}>
          {renderProgressCount()}
        </ProgressText>
        <ProgressText data-testid={'progress-bar-percents-elorish4'}>{greenProgress || 0}%</ProgressText>
      </StyledProgressBarWrapper>
    </>
  )
}

const TableNameWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

const NameWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  user-select: none;
  cursor: pointer;
`
const StyledDoctorNameLetter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 24px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  font-weight: 700;
`
const DoctorName = styled.p`
  margin: 0;
  font-weight: 400;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`
const DoctorItemWrapper = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  padding: 7px 16px 16px 16px;
`

const ProgressBarWrapper = styled.div`
  position: relative;
  width: 100%;
  background-color: var(--color-bg-3);
  border-radius: 5px;
  margin-top: 4px;
`

const Progress = styled.div<{ greenProgress: number; purpleProgress?: number }>`
  height: 2px;
  width: 100%;
  border-radius: 5px;
  transition: width 0.3s ease-in-out;
  background: linear-gradient(
    90deg,
    var(--color-purple) 0%,
    var(--color-purple) ${({ purpleProgress }) => purpleProgress || 0}%,
    var(--color-green) 0%,
    var(--color-green) ${({ greenProgress }) => greenProgress || 0}%,
    var(--color-bg-3) ${({ greenProgress }) => greenProgress || 0}%,
    var(--color-bg-3) 100%
  );
  &::after {
    content: '';
    position: absolute;
    top: -2px;
    left: ${({ greenProgress }) => (greenProgress > 100 ? 100 : greenProgress || 0)}%;
    width: 1px;
    height: 6px;
    background-color: var(--color-green);
  }
`

const ProgressText = styled.div`
  font-size: 10px;
  line-height: 12px;
  color: var(--color-text-1);
`

const StyledProgressBarWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: 2px;
`
