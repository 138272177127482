import { InputNumber } from 'antd'
import { Label } from 'features/additional-order/ui/ModalWrapper/ModalWrapper.styles'
import styled from 'styled-components/macro'

export const LabelStain = styled(Label)`
  min-width: 110px;
`

export const LabelBlock = styled(Label)`
  width: max-content;
`

export const BlockText = styled.div`
  margin-top: 8px;
  color: var(--color-white);
`

export const InputElementStyle = styled(InputNumber)`
  margin-top: 6px;
  height: 24px;
  max-width: 80px;
`
export const StainInputElementStyle = styled.div`
  margin-top: 4px;
`
